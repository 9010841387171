import Link from 'next/link'
import userModel from 'models/user'
import styled, { css } from 'styled-components'
import { Page, PageContent } from 'src/components/Page'
import Gap from 'lib/components/Gap'
import { links } from 'src/links'
import { useAuth } from 'lib/auth/useAuth'
import Heading from '@kiwicom/orbit-components/lib/Heading'
import Button from '@kiwicom/orbit-components/lib/Button'
import Box from '@kiwicom/orbit-components/lib/Box'
import { Stack, Text } from '@kiwicom/orbit-components'
import HpTopSection from 'src/components/HpTopSection'
import { createBasicServerSideProps } from 'lib/next'
import Col from 'lib/components/Col'
import { homepageVideosQueryParams, prefetchVideos, useVideosQuery } from 'src/queries/videos'
import VideoList, { VideoListHomepage } from 'src/components/VideoList'
import BetterButtonLink from 'src/components/BetterButtonLink'
import { homepageUsersQueryParams, prefetchUsers, useUsersQuery } from 'src/queries/users'
import UserList, { UserListHomepage } from 'src/components/UserList'
import Row from 'lib/components/Row'
import Testimonials from 'src/components/Testimonials'
import HowItWorks from 'src/components/HowItWorks'
import SEO from 'src/components/SEO'
import Bugsnag from 'lib/bugsnag'
import CrashyButton from 'lib/bugsnag/CrashyButton'

const Color = styled.div`
  ${({ theme: { orbit }, color }) => css`
    background: ${orbit[color]};
    width: 100px;
    height: 100px;
  `}
`

const SectionTitle = ({ children }) => (
  <>
    <Col alignItems="center" alignSelf="center">
      <Gap gap="56px" />
      <Heading type="title1" as="h2">
        {children}
      </Heading>
      <Gap gap="48px" />
    </Col>
  </>
)
const SectionEndGap = () => <Gap gap="80px" />

const IndexPage = ({ ...props }) => {
  // on public (SSR) pages use mountedUser, which is exposed after didMount
  // and does not trigger hydration inconsistency
  // const { mountedUser, user } = useAuth()

  const {
    data: videos,
    isLoading: areVideosLoading,
    isSuccess,
    isError,
    ...rest
  } = useVideosQuery({
    queryParams: {
      limit: 6, // TODO: na mobilu jen 3!!
      sort: '-createdAt',
    },
  })

  const { data: users, isLoading: areUsersLoading } = useUsersQuery({
    queryParams: homepageUsersQueryParams,
  })

  return (
    <>
      <SEO />
      <HpTopSection />
      <Page bg="cloudLight">
        <PageContent>
          <SectionTitle>Jak to funguje?</SectionTitle>
          {/* <h3>Send some errors by clicking below:</h3> */}
          {/* <button onClick={() => console.notifyWarning('waringing test!!')}>Send handled</button>
          <button
            onClick={() => {
              throw new Error('bad!')
            }}
          >
            Send unhandled
          </button> */}
          {/* <CrashyButton>Trigger a React render error</CrashyButton> */}

          <HowItWorks />
          <Gap gap="56px" />
          <Row alignSelf="center">
            <BetterButtonLink href={links.CREATORS} type="primary">
              Prohlížet tvůrce
            </BetterButtonLink>
            <Gap gap="16px" />
            <BetterButtonLink href={links.CREATORS} type="white">
              Prohlížet videa
            </BetterButtonLink>
          </Row>
          <SectionEndGap />
        </PageContent>
      </Page>
      <Page bg="white">
        <PageContent>
          <SectionTitle>Nejnovější videa</SectionTitle>
          <VideoListHomepage isLoading={areVideosLoading} data={videos} withoutTitle />
          <Col alignSelf="center">
            <Gap gap="24px" />
            <BetterButtonLink href={links.VIDEOS} type="secondary">
              Prohlížet další videa
            </BetterButtonLink>
          </Col>
          <SectionEndGap />
        </PageContent>
      </Page>
      <Page bg="cloudLight">
        <PageContent>
          <SectionTitle>Nejnovější tvůrci</SectionTitle>
          <UserListHomepage isLoading={areUsersLoading} data={users} />
          <Col alignSelf="center">
            <Gap gap="24px" />
            <BetterButtonLink href={links.CREATORS} type="white">
              Prohlížet další tvůrce
            </BetterButtonLink>
          </Col>
          <SectionEndGap />
        </PageContent>
      </Page>
      <Page bg="white">
        <PageContent>
          <SectionTitle>Co říkají uživatelé</SectionTitle>

          <Testimonials />

          <Gap gap="72px" />
        </PageContent>
      </Page>
      <Page bg="cloudLight">
        <PageContent>
          <Gap gap="72px" />
          <Col alignSelf="center" alignItems="center">
            <Heading type="title3">Přidejte se ke spokojeným uživatelům:</Heading>
            <Gap gap="12px" />

            <Row alignSelf="center">
              <BetterButtonLink href={links.CREATORS} type="primary">
                Prohlížet tvůrce
              </BetterButtonLink>
              <Gap gap="16px" />
              <BetterButtonLink href={links.CREATORS} type="secondary">
                Prohlížet videa
              </BetterButtonLink>
            </Row>
          </Col>
          <Gap gap="72px" />
        </PageContent>
      </Page>
    </>
  )
}

export default IndexPage

export const getServerSideProps = createBasicServerSideProps({
  prefetchQueriesWithParams: [
    prefetchUsers(() => homepageUsersQueryParams),
    prefetchVideos(() => homepageVideosQueryParams),
  ],
})
